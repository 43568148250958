import React from 'react';
import { isBrowser } from 'utils/browser';
import Layout from 'components/Layout';
import EventCardAnimation from 'components/EventCardAnimation';
import { SEO } from 'components/Seo';

export default function HowItWorks() {
  if (!isBrowser) return null;
  window.location.replace('/');
  return (
    <Layout>
      <EventCardAnimation />
    </Layout>
  );
}

export const Head = () => {
  return <SEO title="Tutadoo | How it works" />;
};
